import {ApolloClient, InMemoryCache, from, split} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {removeTypenameFromVariables} from '@apollo/client/link/remove-typename';
import {getMainDefinition} from '@apollo/client/utilities';
import {Action, Dispatch} from '@reduxjs/toolkit';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import Router from 'next/router';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import fragment from '../../node_modules/@eon.cz/apollo13-graphql-web/lib/fragmentTypesV3.json';
import {BackendEndpoints} from '../common/BackendEndpoints';
import {NotificationsActionCreator} from '../common/components/notifications/actions/NotificationsActions';
import {statusCode} from '../common/constants';
import {GraphQLErrorResolver} from '../common/graphql/GraphQLErrorResolver';
import {NotificationType} from '../models/notification/NotificationModel';
import {RootState} from './store';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const removeTypenameLink = removeTypenameFromVariables();

const errorLink = (dispatch: Dispatch<Action>) =>
    onError(({graphQLErrors, networkError}) => {
        if (typeof graphQLErrors === 'object' && graphQLErrors) {
            GraphQLErrorResolver.resolveErrors(dispatch, graphQLErrors);
            // If there are GraphQL errors, ignore network
        }
        if (networkError) {
            const {addNotification} = NotificationsActionCreator(dispatch);
            if (networkError && 'statusCode' in networkError && statusCode.includes(networkError.statusCode)) {
                apolloClient.cache.reset().then(() => {
                    Router.push({pathname: '/'}).then(() => {
                        dispatch({type: 'RESET_APP'});
                        addNotification({
                            type: NotificationType.ERROR,
                            text: <FormattedMessage id="error.network" />,
                        });
                    });
                });
            } else {
                addNotification({
                    type: NotificationType.ERROR,
                    text: <FormattedMessage id="error.network" />,
                });
            }
        }
    });

const createClient = (initialState: RootState | null, dispatch: Dispatch<Action>) => {
    const httpLink = createUploadLink({
        uri: `/api/${BackendEndpoints.GRAPHQL}`,
        fetchOptions: {credentials: 'same-origin'},
        headers: {
            'X-Apollo-Operation-Name': 'graphql',
        },
    });

    const link =
        typeof window !== 'undefined'
            ? split(({query}) => {
                  const definition = getMainDefinition(query);
                  return definition.kind === 'OperationDefinition' && (definition.operation === 'query' || definition.operation === 'mutation');
              }, httpLink)
            : httpLink;

    return new ApolloClient({
        connectToDevTools: typeof window !== 'undefined',
        ssrMode: typeof window !== 'undefined',
        assumeImmutableResults: false,
        link: from([errorLink(dispatch), removeTypenameLink, link]),
        cache: new InMemoryCache({
            possibleTypes: fragment.possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        adresniMista: {
                            merge: true,
                        },
                    },
                },
            },
        }).restore(initialState || {}),
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
            },
        },
    });
};

export let apolloClient: ApolloClient<any>;

export const initApollo = (initialState: RootState | null, dispatch: Dispatch<Action>) => {
    const _apolloClient = apolloClient ?? createClient(initialState, dispatch);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
};

export function useApollo(pageProps: any, dispatch: Dispatch<Action>) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initApollo(state, dispatch), [dispatch]);
}
