import {useQuery} from '@apollo/client';
import {Query} from '@eon.cz/apollo13-graphql-web';
import {AppBar, Box, IconButton, Step, StepLabel, Stepper, Toolbar, Typography, useMediaQuery} from '@mui/material';
import {Store} from 'client/lib/store';
import {useRouter} from 'next/router';
import {FC, ReactNode, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {theme} from '../../../lib/theme';
import {NotificationType} from '../../../models/notification/NotificationModel';
import {useCommonAction} from '../../action/CommonAction';
import {PageRoute, formMapPath} from '../../constants';
import {CommonQueries} from '../../graphql/CommonQueries';
import {setActiveStep} from '../../reducers/CommonSlice';
import {Div} from '../../styledComponents/Div';
import {sanitizeHTML, transformPath} from '../../utils/CommonUtils';
import {LoadingDialog} from '../dialogs/LoadingDialog';
import {useAddNotification} from '../notifications/actions/NotificationsActions';
import {NotificationsComponent} from '../notifications/components/NotificationsComponent';
import {EgdLogo} from './EgdLogo';
import {FormOff} from './FormOff';

type Props = {
    readonly statusCode?: number;
    readonly children: ReactNode;
};

export const PageLayout: FC<Props> = ({children}) => {
    // methods
    const {push, pathname, events} = useRouter();
    const matches = useMediaQuery('(max-width:500px)');
    const {closeNotification, addNotification} = useAddNotification();
    const {resetFormData} = useCommonAction();

    // query
    const {loading, data, refetch} = useQuery<Query>(CommonQueries.gql.odstaveniFormulare, {fetchPolicy: 'no-cache'});

    // local state
    const [showChildren, setShowChildren] = useState(true);

    const {notifications} = useSelector((state: Store) => state?.notifications);
    const {activeStep, formSuccessSteps, endOfForm} = useSelector((state: Store) => state?.common);

    // local variables
    const odstavka = data?.prvniParalelniPripojeni?.odstavka;
    const path = transformPath(pathname, odstavka?.aktivni);

    // functions
    const handleRouteEGD = () => push('https://www.egd.cz');
    const handleRefresh = useCallback(() => refetch(), [refetch]);

    useLayoutEffect(() => {
        if (!formSuccessSteps?.[activeStep - 1] && pathname !== PageRoute.VSTUP) {
            addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.page.pathname" />});
            setShowChildren(false);
            setActiveStep(0);
            const step = formMapPath(formSuccessSteps?.[activeStep] ? activeStep : 0);
            push(step ?? PageRoute.VSTUP).then(() => setShowChildren(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, formSuccessSteps]);

    useEffect(() => {
        if (pathname === PageRoute.VSTUP) {
            setActiveStep(0);
            resetFormData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (endOfForm && pathname !== PageRoute.PODEKOVANI) {
            setActiveStep(0);
            resetFormData();
            push(PageRoute.VSTUP);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endOfForm, pathname]);

    useEffect(() => {
        events.on('routeChangeStart', handleRefresh);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            events.off('routeChangeStart', handleRefresh);
        };
    }, [events, handleRefresh]);

    return (
        <Div
            id="body"
            sx={{
                backgroundColor: 'background.default',
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            {/* Notifications */}
            <NotificationsComponent notifications={notifications} onClose={closeNotification} />

            {/* Header */}
            <Div
                sx={{
                    height: 80,
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    margin: '0 auto',
                    maxWidth: 1680,
                }}
            >
                <IconButton
                    sx={{
                        marginLeft: 5.5,
                    }}
                    onClick={handleRouteEGD}
                    data-testid={`egd-logo-button`}
                >
                    <EgdLogo width={150} height={50} pathFill={theme.palette.error.main} />
                </IconButton>
            </Div>

            {/* Appbar */}
            <AppBar
                position="relative"
                color="secondary"
                sx={{
                    minHeight: 204,
                    backgroundImage: "url('/static/images/AppBarBackground.svg')",
                    backgroundPosition: 'right',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0 auto',
                        maxWidth: 1680,
                    }}
                >
                    <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                            marginTop: 2,
                        }}
                        component="div"
                    >
                        <div dangerouslySetInnerHTML={{__html: sanitizeHTML(path)}} />
                    </Typography>
                    <Typography
                        variant={matches ? 'h4' : 'h3'}
                        color="inherit"
                        sx={{
                            marginTop: 2,
                            marginLeft: -1,
                            minWidth: 200,
                            maxWidth: matches ? 700 : undefined,
                            padding: 1,
                            textAlign: matches ? 'center' : 'left',
                        }}
                    >
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage id="portal" />
                        </Box>
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* Zobrazení stepperu */}
            {odstavka?.aktivni && (
                <Stepper
                    sx={{
                        backgroundColor: 'background.default',
                        maxWidth: 1450,
                        margin: 'auto',
                        marginTop: 3,
                        overflowX: matches ? 'auto' : 'hidden',
                    }}
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {Object.keys(PageRoute)?.map((label, index) => (
                        <Step
                            key={index}
                            sx={{
                                '& MuiStep-alternativeLabel': {
                                    width: '80%',
                                },
                            }}
                            data-testid={`step-${label}`}
                        >
                            <StepLabel>
                                <FormattedMessage id={`layout.${label.toUpperCase()}`} />
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}

            {/* Main */}
            <Div
                role="main"
                id="main"
                sx={{
                    display: 'block',
                    padding: '82px 16px',
                    maxWidth: 1450,
                    margin: 'auto',
                    height: '100%',
                }}
            >
                {loading && <LoadingDialog open />}
                {showChildren && odstavka?.aktivni && children}
                {!odstavka?.aktivni && !loading && <FormOff message={odstavka?.formZakazanHtml} refetch={handleRefresh} />}
            </Div>

            {/* Footer */}

            <Div
                sx={{
                    width: '100%',
                    height: 86,
                    left: 0,
                    bottom: 0,
                    position: 'absolute',
                    ackgroundColor: 'secondary.main',
                    background: 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,#261B62 100%)',
                }}
            >
                <Div
                    sx={(theme) => ({
                        paddingTop: theme.spacing(4),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: 1,
                        maxWidth: 1680,
                        margin: 'auto',
                    })}
                >
                    <EgdLogo width={100} height={33} pathFill={theme.palette.background.default} />
                    <Typography
                        component={'span'}
                        variant="body2"
                        sx={{
                            color: 'background.default',
                        }}
                    >
                        <FormattedMessage id="footer" values={{year: new Date().getFullYear()}} />
                    </Typography>
                </Div>
            </Div>
        </Div>
    );
};
